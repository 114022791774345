<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import {setOpenId, getOpenId} from "@/utils/common";
import {mapState, mapActions} from 'vuex'
import {get, post} from '@/api/httpHelp'

export default {
  name: "WxLayout",
  data() {
    return {
      url: {
        getAccessToken: '/wx/getAccessToken',
        listSignUp: '/listSignUp'
      }
    }
  },
  computed: {
    ...mapState('match', ['matchConfig'])
  },
  created() {
    // 进到了微信的页面以后
    // 首先判断 路由地址中是否有 code 值
    // setOpenId('owxcx58IANTn9J-bWXVLgIfF81sc')
    let openId = getOpenId()
    if (openId && openId !== '') {
      // 如果当前 localstorage 中存在 openId 说明之前已经进过系统
      // 同步判断当前是否已经有报名信息了
      this.hasMatchConfig().then((matchConfigFlag) => {
        if (matchConfigFlag) {
          // 如果获取完毕数据
          // 判断当前 openId 是否已经报过名了
          this.checkHasData(openId).then((res) => {
            if (res && res.length > 0) {
              this.$router.push({
                path: '/wx/signUpList'
              })
            } else {
              this.$router.push({
                path: '/wx/home'
              })
            }
          })
        }
      })
      return
    }

    // code 判断
    let code = this.getParameter('code')
    if (code && code !== '') {
      this.getOpenId(code).then((res) => {
        if (res && res !== '') {
          this.$router.push({
            path: '/wx/signUpList'
          })
        } else {
          this.$router.push({
            path: '/wx/home'
          })
        }
      })
    } else {
      let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbc2307c92d5395b7&redirect_uri=' + encodeURIComponent("https://wxbm.121xuexi.com/#/wx") + '&response_type=code&scope=snsapi_base&state=dxtx&connect_redirect=1#wechat_redirect'
      //window.location.replace(url);
      window.location.href = url;
    }
  },
  methods: {
    ...mapActions('match', ['getMatchConfig']),
    async hasMatchConfig() {
      let d = false
      if (Object.keys(this.matchConfig).length > 0) {
        return true
      }
      await this.getMatchConfig().then(() => {
        d = true
      })
      return d
    },

    async checkHasData(openId) {
      let d = null
      await get(this.url.listSignUp, {
        openId: openId
      }).then(res => {
        if (res != null) {
          d = res.result
        }
      })
      return d
    },

    async getOpenId(code) {
      let d = ''
      await post(this.url.getAccessToken, {
        code: code
      }).then(res => {
        if (res != null && res.result.openid) {
          setOpenId(res.result.openid)
          d = res.result.openid
        }
      })
      return d
    },

    getParameter(str) {
      let url = window.location.href.split("?")[1]
      if (!url) {
        return null
      }
      url = url.substring(0, url.length - 2)
      let arr = url.split("&")
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          let itemArr = arr[i].split("=")
          if (itemArr && itemArr.length > 0) {
            if (str === itemArr[0]) {
              return itemArr[1]
            }
          }
        }
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>